import { FocusableElement } from 'tabbable';

const invisibleFocus = (
  $element: FocusableElement,
  options: FocusOptions = { preventScroll: true },
): void => {
  const onBlur = () => {
    $element.removeEventListener('blur', onBlur);
    $element.classList.remove('has-invisible-focus');
  };

  $element.addEventListener('blur', onBlur);
  $element.classList.add('has-invisible-focus');
  $element.focus(options);
};

export default invisibleFocus;
