import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from '../utils/lockScrolling';
import onOutsideClick from '../utils/onOutsideClick';

let dialog: A11yDialog | null = null;
let $currentOverlay: HTMLElement | null = null;

export const openOverlay = (overlay: string) => {
  const $overlay =
    document.getElementById(overlay) ??
    document.getElementById(`overlay-${overlay}`);

  if (!$overlay) {
    return;
  }

  const $frame = $overlay.querySelector<HTMLIFrameElement>('iframe');
  const src = $frame?.getAttribute('data-src');

  if (!$frame || !src) {
    return;
  }

  if (dialog) {
    if ($currentOverlay && $currentOverlay === $overlay) {
      return;
    }

    dialog.hide();
  }

  dialog = new A11yDialog($overlay);

  dialog.on('show', () => {
    $currentOverlay = $overlay;
    $frame.src = src;

    disableBodyScroll($overlay, {
      reserveScrollBarGap: true,
    });

    const $menu = document.querySelector('.header__menu');

    onOutsideClick({
      target: $overlay,
      isOutside($clickedElement) {
        return !$menu || !$menu.contains($clickedElement);
      },
      onOutsideClick() {
        dialog?.hide();
      },
      disabledAfterOutsideClick: true,
    });
  });

  dialog.on('hide', () => {
    enableBodyScroll($overlay);
    dialog?.destroy();
  });

  dialog.on('destroy', () => {
    $currentOverlay = null;
    dialog = null;
    $frame.removeAttribute('src');
  });

  dialog.show();
};

export const closeOverlay = () => {
  dialog?.hide();
};
