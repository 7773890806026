import { on } from 'delegated-events';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import invisibleFocus from '../utils/invisibleFocus';
import { disableBodyScroll, enableBodyScroll } from '../utils/lockScrolling';
import moveFocus from '../utils/moveFocus';
import { closeOverlay, openOverlay } from './overlay';

let focusTrap: FocusTrap | null = null;

on('click', 'button.header__menu-button', (event) => {
  const { currentTarget: $button } = event;
  const target = $button.getAttribute('aria-controls');
  const $target = target ? document.getElementById(target) : null;

  if (!$target) {
    return;
  }

  if (!focusTrap) {
    focusTrap = createFocusTrap($target, {
      onActivate() {
        $button.setAttribute('aria-expanded', 'true');
      },
      onPostActivate() {
        disableBodyScroll($target, {
          reserveScrollBarGap: true,
        });

        const $firstLink =
          $target.querySelector<HTMLElement>('.header__menu-link');
        if ($firstLink) {
          moveFocus($firstLink);
        }
      },
      onDeactivate() {
        $button.setAttribute('aria-expanded', 'false');
        $target.hidden = true;
      },
      initialFocus: false,
      returnFocusOnDeactivate: false,
      clickOutsideDeactivates: true,
      onPostDeactivate() {
        enableBodyScroll($target);
        invisibleFocus($button);
        focusTrap = null;
      },
    });

    $target.hidden = false;
    focusTrap.activate();
  } else {
    focusTrap.deactivate();
  }
});

on(
  'click',
  '.header__menu-close-button, .header__menu-overlay-backdrop',
  () => {
    focusTrap?.deactivate();
  },
);

on('click', 'a.header__menu-link', (event) => {
  const { currentTarget: $link } = event;
  const href = $link.getAttribute('href');

  if (href && href.startsWith('#')) {
    event.preventDefault();
    focusTrap?.deactivate();
    openOverlay(href.substring(1));
  }
});

on('click', '.header__logo', (event) => {
  event.preventDefault();
  closeOverlay();

  // TODO: Zoom out
});
