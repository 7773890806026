import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from '../utils/lockScrolling';
import whenReady from '../utils/whenReady';

whenReady().then(() => {
  const $welcome = document.querySelector<HTMLElement>('.welcome');

  if ($welcome) {
    const dialog = new A11yDialog($welcome);

    dialog.on('show', () => {
      disableBodyScroll($welcome, {
        reserveScrollBarGap: true,
      });
    });

    dialog.on('hide', () => {
      // TODO: Zoom in
      dialog.destroy();
      enableBodyScroll($welcome);
    });

    dialog.show();
  }
});
