type OnOutsideClickConfiguration = {
  target: HTMLElement;
  isOutside: null | (($clickedElement: HTMLElement) => boolean);
  onOutsideClick: () => void;
  disabledAfterOutsideClick: boolean;
};

const onOutsideClick = (configuration: OnOutsideClickConfiguration) => {
  const clickHandler = (event: MouseEvent) => {
    const { target: $clickedElement } = event;

    if ($clickedElement instanceof HTMLElement) {
      if (
        !configuration.target.contains($clickedElement) ||
        configuration.target !== $clickedElement
      ) {
        if (
          configuration.isOutside &&
          !configuration.isOutside($clickedElement)
        ) {
          return;
        }

        configuration.onOutsideClick();

        if (configuration.disabledAfterOutsideClick) {
          document.removeEventListener('click', clickHandler);
        }
      }
    }
  };

  document.addEventListener('click', clickHandler);

  return () => {
    document.removeEventListener('click', clickHandler);
  };
};

export default onOutsideClick;
